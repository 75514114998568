export const imagesLink = {
  appleIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/apple.svg',
  arrowDown: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/arrow-down.svg',
  arrowLeft: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/arrow-left.svg',
  arrowRight: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/arrow-right.svg',
  bannerForeGround: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/banner-foreground.svg',
  beachForeground: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/beachForeground.svg',
  beachBackground: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/beach-background.svg',
  claimIllustration: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/claim-illustration.svg',
  familyClaimApply: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/family_claim_apply.svg',
  howToClaimBackdropBanner: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/how-to-claim-backdrop-banner.svg',
  indoMap: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/indo-map.svg',
  claimIllustration1: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/klaim%20illustration-03%201.svg',
  claimIllustration2: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/klaim%20illustration-04%201.svg',
  locationIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/location-icon.svg',
  manCallingAfterAccident: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/man-calling-after-accident.svg',
  manHoldingIpad: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/man-holding-ipad.svg',
  manTryingToRepair: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/man-trying-to-repair.svg',
  operationalUpdate: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/operational_update.svg',
  playStoreIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/playstore-icon.svg',
  roadAssistanceBannerBackdrop: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/raod-assistance-banner-backdrop.svg',
  roadAssistanceBannerImage: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/raod-assistance-banner-image.svg',
  searchIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/search-icon.svg',
  towTruck: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/tow-truck.svg',
  yellowLine: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/yellow_line.svg',
  autoText: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/auto_text.svg',
  roadAssistanceBannerMobile: `https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/roadside-banner-mobile.svg`,
  greenShield: 'https://storage.googleapis.com/pp_img/tap-travel-website/Product%20Page/green%20shield.svg',

}

export const langStrings = {
  auto: 'auto',
  roadAssistance: 'roadside assistance',
  highWaySos: 'SOS Jalan Raya',
  crane: 'Derek',
  onSiteAssistance: 'Bantuan di Lokasi',
  termsAndCondition: 'Syarat dan Ketentuan',
  body4Heading: 'Yuk Jelajahi Informasi Lebih Lanjut!',
  body5Heading: 'Dapatkan Perlindunganmu disini!',
  protectYouWithSmallSteps: 'Lindungi dirimu hanya dengan beberapa ketukan.',
  protectWithSmallSteps: 'Lindungi kendaraan kamu hanya dengan beberapa ketukan.',
  downloadProductInformation: 'Unduh Informasi Produk'
}

export const body1List = [
  {
    text: 'Selamatkan diri kamu dan penumpang kendaraan ke tempat aman terdekat dari kendaraan.'
  },
  {
    text: 'Cara termudah untuk menginformasikan lokasi tepat di mana kamu berada:',
    children: [
      { text: 'Nama jalan, kilometer, nama daerah atau kota.' },
      { text: 'Menyebutkan nama gedung, toko, atau spot terkenal di sekitar kamu yang bisa jadi petunjuk.' },
      { text: 'Semakin tepat kamu kasih tahu lokasimu, semakin cepat bantuan Roadside Assistance dapat membantu' },
      { text: 'Persiapkan STNK, KTP, dan SIM untuk verifikasi.' },
    ]
  },
  {
    text: 'Kontak ke nomor <b> <u>082111376989</u> (Telepon & WhatsApp) </b> segera!'
  }
]

export const body2ListContentOnsiteAccident = [
{
  heading: 'Akibat kecelakaan',
  text: [
    'Kami akan menderek kendaraanmu ke bengkel terdekat jika bermasalah akibat kecelakaan!',
    'Jika Kendaraan masuk dalam parit/lubang, kami akan menarik/mengeluarkan kendaraan tersebut sampai berada di badan jalan dengan maksimal biaya 0,5% dari Harga Pertanggungan.'
  ]
},
{
  heading: 'Akibat Breakdown (mogok)',
  text: [
    'Kami akan menderek kendaraan yang mengalami kerusakan mesin/mogok menuju bengkel terdekat'
  ]
}
]

export const body2ListContentTowTruck = [
  {
    heading: 'Layanan Kehabisan Bensin',
    text: [
      'Jika kendaraan Anda mengalami mogok karena kehabisan bensin diperjalanan (lebih dari 1 KM dari rumah/tempat parkir), kami akan mengirimkan 4 liter bensin sehingga memungkinkan Anda mencapai pompa bensin terdekat.',
      'Maksimum limit: 2 kali per periode Polis'
    ]
  },
  {
    heading: 'Layanan Battery boosting, Jumpstarting',
    text: [
      'Mobil Kamu tidak dapat hidup karena accu / aki mobil bermasalah? Hubungi kami, Team kami akan datang ke tempat kamu untuk membantu menyalakan kendaraan.',
      'Maksimum limit: 2 kali per periode Polis'
    ]
  },
  {
    heading: 'Layanan Locksmith / Membuka pintu saat Kunci tertinggal didalam kendaraan',
    text: [
      'Kunci Kamu tertinggal didalam kendaraan? Hubungi kami, Team kami akan datang ke tempat Kamu untuk membantu membuka pintu kendaraan.',
      'Jika kendaraan tidak dapat dibuka di lokasi kejadian tanpa menimbulkan kerusakan pada kendaraan, maka kendaraan akan diderek ke bengkel terdekat. Biaya membuka pintu dan perbaikan lainnya yang timbul di bengkel tersebut akan menjadi tanggungan kamu.'
    ]
  },
  {
    heading: 'Layanan Bantuan Penggantian Ban',
    text: [
      'Jika kamu mengalami ban kempes dan kesulitan dalam memasang ban penggantinya, hubungi kami, tim kami akan datang ke tempat kamu untuk membantu memasang ban serep kendaraan kamu.',
      'Jika kondisi ban serep yang ada pada kendaraan tidak memungkinkan/ tidak aman untuk dipasang, maka kendaraan akan diderek ke bengkel terdekat.',
      'Maksimum limit: 3 kali per periode Polis'
    ]
  }
];


export const body3ListContent = [
  {text: 'Segera menghubungi Pelayanan Roadside Assistance TAP Insure untuk melaporkan kecelakaan dan mendapatkan layanan bantuan di 082111376989 (Telepon & WhatsApp) dan informasikan secara benar dan lengkap semua informasi terkait kecelakaan yang dialami.'},
  {text: 'Roadside Assistance TAP Insure beroperasi 24 jam sehari dari Senin s/d Minggu dan melayani daerah Jabodetabek, Bandung, Yogyakarta, Solo, Semarang, Malang, dan Surabaya.'},
  {text: 'Tidak melakukan pembayaran atau janji melakukan pembayaran kepada pihak lain tanpa persetujuan tertulis dari Pelayanan Roadside Assistance TAP Insure.'},
  {text: 'Pelayanan Roadside Assistance TAP Insure hanya berlaku untuk kendaraan bermotor roda empat atau lebih dengan polis comprehensive dan tercatat memiliki tambahan jaminan Roadside Assistance TAP Insure yang mengalami kecelakaan dijalan raya'},
  {text: 'Pelayanan Roadside Asisstance TAP Insure tidak termasuk jaminan perbaikan dan/penggantian'},
  {text: 'Syarat dan ketentuan yang tidak tercantum pada Panduan Roadside Assistance ini akan mengacu pada Syarat dan Ketentuan Polis Kendaraan Anda.'},
]

export const riplayDocUrl = 'https://storage.googleapis.com/pp_img/tap-travel-website/RIPLAY%20Docs/Auto.pdf'

export const PROPERTY_TYPE = [
  {
    key: 'derek',
    activeIcon: imagesLink.towTruck,
    label: 'Derek',
    productName:'derek',
  },
  {
    key: 'assistance',
    activeIcon: imagesLink.manCallingAfterAccident,
    label: 'Bantuan di Lokasi',
    productName:'assistance',
  }
]