import React, { useState, useEffect } from 'react'
import { HeaderContainer } from './Header.style'
import {imagesLink} from '../../constants/imageLink'
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 480);
  const navigate = useNavigate();

  const handleMouseEnter = (menuItem) => {
    if (window.innerWidth > 480) setDropdownVisible(menuItem);
  };

  const handleMouseLeave = (menuItem) => {
      setDropdownVisible(null);
  };

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!mobileMenuVisible);
  };

  const navigateTo = (path) => {
    navigate({ pathname: path });
    setMobileMenuVisible(false);
  };

  const toggleDropdown = (menuItem) => {
    if (window.innerWidth <= 480) {
      setDropdownVisible(dropdownVisible === menuItem ? null : menuItem);
    }
  };

  useEffect(() => {
    const handleResize = () => {
        setIsMobileView(window.innerWidth <= 480);
        if (window.innerWidth > 480) {
          setMobileMenuVisible(false);
        }
      };
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      }
  },[])

  return (
    <HeaderContainer>
        <div className='header-inner'>
            <div onClick={() => navigateTo('/')}>
                <img src={imagesLink.tapInsureLogo} className='logo'/>
            </div>
            {!isMobileView ? (
                <>
                    <ul className={`header-item`}>
                        <li onClick={() => navigateTo('/')}>
                            Beranda
                        </li>
                        <li className='header-dropdown' onMouseEnter={() => handleMouseEnter('produk')} onMouseLeave={handleMouseLeave} onClick={() => toggleDropdown('produk')}>
                            Produk <img src={imagesLink.arrowDown}/>
                            {dropdownVisible === 'produk' && (
                                <ul className='dropdown-menu'>
                                    <li onClick={() => navigateTo('/produk-auto')}>Auto</li>
                                    <li onClick={() => navigateTo('/produk-travel')}>Travel</li>
                                    <li onClick={() => navigateTo('/produk-extended-warranty')}>Extended Warranty</li>
                                    <li onClick={() => navigateTo('/produk-safe')}>Safe</li>
                                    <li onClick={() => navigateTo('/produk-device')}>Device</li>
                                    <li onClick={() => navigateTo('/produk-property')}>Property</li>
                                    <li onClick={() => navigateTo('/produk-lainnya')}>Lainnya </li>
                                </ul>
                            )}
                        </li>
                        <li className='header-dropdown' onMouseEnter={() => handleMouseEnter('tentangKami')} onMouseLeave={handleMouseLeave} onClick={() => toggleDropdown('tentangKami')}>
                            Tentang Kami <img src={imagesLink.arrowDown}/>
                            {dropdownVisible === 'tentangKami' && (
                                <ul className='dropdown-menu header-width'>
                                    <li onClick={() => navigateTo('/tentang-kami')}>Tentang TAP Insure</li>
                                    <li onClick={() => navigateTo('/visi-misi')}>Visi, Misi & Nilai Inti</li>
                                    <li onClick={() => navigateTo('/organisasi')}>Jajaran Pengurus, Struktur Organisasi & Komite</li>
                                    <li onClick={() => navigateTo('/tata-kelola-perusahaan')}>Tata Kelola Perusahaan</li>
                                </ul>
                            )}
                        </li>
                        <li className='header-dropdown' onMouseEnter={() => handleMouseEnter('layanan')} onMouseLeave={handleMouseLeave}  onClick={() => toggleDropdown('layanan')}>
                            Layanan Pelanggan <img src={imagesLink.arrowDown}/>
                            {dropdownVisible === 'layanan' && (
                                <ul className='dropdown-menu'>
                                    <li onClick={() => navigateTo('/hubungi-kami')}>Hubungi Kami</li>
                                    <li onClick={() => navigateTo('/klaim')}>Klaim Asuransi</li>
                                    <li onClick={() => navigateTo('/roadside-assistance')}>Roadside Assistance</li>
                                    <li onClick={() => navigateTo('/bengkel-rekanan')}>Daftar Bengkel Rekanan</li>
                                    <li onClick={() => navigateTo('/whistleblowing')}>Whistleblowing</li>
                                </ul>
                            )}
                        </li>
                        <li className='header-dropdown' onMouseEnter={() => handleMouseEnter('publikasi')} onMouseLeave={handleMouseLeave} onClick={() => toggleDropdown('publikasi')}>
                            Publikasi <img src={imagesLink.arrowDown}/>
                            {dropdownVisible === 'publikasi' && (
                                <ul className='dropdown-menu publication-head'>
                                    {/* <li>Berita Pers</li>
                                    <li>Blog</li> */}
                                    <li onClick={() => navigateTo('/laporan-keuangan')}>Laporan Keuangan</li>
                                </ul>
                            )}
                        </li>
                    </ul>
                </>
        ) : 
        (
            <>
                <button className='mobile-menu-button' onClick={toggleMobileMenu}>
                    <img src={imagesLink.MenuIcon}/>
                </button>
                <div className={`mobile-menu ${mobileMenuVisible ? "open" : ""}`}>
                <button className="close-button" onClick={toggleMobileMenu}>
                    <img src={imagesLink.crossIcon} alt="Close" />
                </button>
                    <ul className={`mobile-menu-items`}>
                        <li onClick={() => navigateTo('/')}>
                            Beranda
                        </li>
                        <li className='header-dropdown' onMouseEnter={() => handleMouseEnter('produk')} onMouseLeave={handleMouseLeave} onClick={() => toggleDropdown('produk')}>
                            Produk <img src={imagesLink.arrowDown}/>
                            {dropdownVisible === 'produk' && (
                                <ul className='dropdown-menu'>
                                    <li onClick={() => navigateTo('/produk-auto')}>Auto</li>
                                    <li onClick={() => navigateTo('/produk-travel')}>Travel</li>
                                    <li onClick={() => navigateTo('/produk-extended-warranty')}>Extended Warranty</li>
                                    <li onClick={() => navigateTo('/produk-safe')}>Safe</li>
                                    <li onClick={() => navigateTo('/produk-device')}>Device</li>
                                    <li onClick={() => navigateTo('/produk-property')}>Property</li>
                                    <li onClick={() => navigateTo('/produk-lainnya')}>Lainnya </li>
                                </ul>
                            )}
                        </li>
                        <li className='header-dropdown' onMouseEnter={() => handleMouseEnter('tentangKami')} onMouseLeave={handleMouseLeave} onClick={() => toggleDropdown('tentangKami')}>
                            Tentang Kami <img src={imagesLink.arrowDown}/>
                            {dropdownVisible === 'tentangKami' && (
                                <ul className='dropdown-menu header-width'>
                                    <li onClick={() => navigateTo('/tentang-kami')}>Tentang TAP Insure</li>
                                    <li onClick={() => navigateTo('/visi-misi')}>Visi, Misi & Nilai Inti</li>
                                    <li onClick={() => navigateTo('/organisasi')}>Jajaran Pengurus, Struktur Organisasi & Komite</li>
                                    <li onClick={() => navigateTo('/tata-kelola-perusahaan')}>Tata Kelola Perusahaan</li>
                                </ul>
                            )}
                        </li>
                        <li className='header-dropdown' onMouseEnter={() => handleMouseEnter('layanan')} onMouseLeave={handleMouseLeave}  onClick={() => toggleDropdown('layanan')}>
                            Layanan Pelanggan <img src={imagesLink.arrowDown}/>
                            {dropdownVisible === 'layanan' && (
                                <ul className='dropdown-menu'>
                                    <li onClick={() => navigateTo('/hubungi-kami')}>Hubungi Kami</li>
                                    <li onClick={() => navigateTo('/klaim')}>Klaim Asuransi</li>
                                    <li onClick={() => navigateTo('/roadside-assistance')}>Roadside Assistance</li>
                                    <li onClick={() => navigateTo('/bengkel-rekanan')}>Daftar Bengkel Rekanan</li>
                                    <li onClick={() => navigateTo('/whistleblowing')}>Whistleblowing</li>
                                </ul>
                            )}
                        </li>
                        <li className='header-dropdown' onMouseEnter={() => handleMouseEnter('publikasi')} onMouseLeave={handleMouseLeave} onClick={() => toggleDropdown('publikasi')}>
                            Publikasi <img src={imagesLink.arrowDown}/>
                            {dropdownVisible === 'publikasi' && (
                                <ul className='dropdown-menu publication-head'>
                                    {/* <li>Berita Pers</li>
                                    <li>Blog</li> */}
                                    <li onClick={() => navigateTo('/laporan-keuangan')}>Laporan Keuangan</li>
                                </ul>
                            )}
                        </li>
                    </ul>
                </div>
            </>
        )
        
        }
        </div>
    </HeaderContainer>
  )
}

export default Header
