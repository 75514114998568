import React, {useState} from 'react'
import Slider from "react-slick";
import {ProgressContainer, ProgressBar} from "./CustomSlider.style";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


const CustomSlider = ({slidesLength, children}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const calculateThumbPosition = () => ((activeIndex + 1) / slidesLength) * 100;
    const thumbWidth = 100 / slidesLength;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (current, next) => setActiveIndex(next),
      appendDots: () => (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
          <ProgressContainer>
            <ProgressBar fill={calculateThumbPosition() - thumbWidth} thumbWidth={thumbWidth} />
          </ProgressContainer>
        </div>
      ),
      arrows: false,
      adaptiveHeight: true
    };

  return (
    <Slider {...settings}>
      {children}
    </Slider>
  )
}

export default CustomSlider
