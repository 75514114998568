import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import {
  GridContainer,
  HolderTitle,
  InsuredFormWrapper,
  Title,
} from "../elements";
import FormHook from "../../../components/FormComponents/FormHook";
import { Formik } from "formik";
import TextInputV2 from "../../../components/FormComponents/InputTextV2";
import BooleanValue from "../../../components/FormComponents/Booleanvalue";
import TextInput from "../../../components/FormComponents/InputText";
import { format, subDays, subMonths } from "date-fns";
import Modal from "../../../components/Modal";
import DateOfBirth from "../../../components/DateOfBirth";
import { get } from "lodash";

const INTIIAL_VALUES = {
  name: "",
  ktp: "",
  passport_no: "",
  relationship: "",
  gender: "",
  dob: "",
  kitas_kitap: "",
  citizenship: "",
};

const DOMESTIC_INDONESIAN_SCHEMA = {
  name: Yup.string()
    .required("Nama tidak boleh kosong")
    .max(60, "Nama maksimal 60 karakter")
    .nullable(),
  citizenship: Yup.string()
    .required("Kewarganegaraan tidak boleh kosong")
    .nullable(),
  ktp: Yup.string()
    .min(16, "KTP minimal 16 karakter")
    .max(16, "KTP maksimal 16 karakter")
    .required("KTP tidak boleh kosong"),
  gender: Yup.string().required("Jenis Kelamin tidak boleh kosong"),
  relationship: Yup.string().required("Hubungan keluarga tidak boleh kosong"),
  dob: Yup.string().required("Tanggal lahir tidak boleh kosong"),
};

const DOMESTIC_NON_INDONESIAN_SCHEMA = {
  name: Yup.string()
    .required("Nama tidak boleh kosong")
    .max(60, "Nama maksimal 60 karakter")
    .nullable(),
  citizenship: Yup.string().required("Nama tidak boleh kosong").nullable(),
  kitas_kitap: Yup.string()
    .matches(
      /^[a-zA-Z0-9-]+$/,
      "KITAS/KITAP hanya boleh berisi huruf, angka, dan tanda hubung"
    )
    .min(1, "KITAS/KITAP minimal 1 karakter")
    .max(12, "KITAS/KITAP maksimal 12 karakter")
    .required("KITAS/KITAP tidak boleh kosong"),
  gender: Yup.string().required("Jenis Kelamin tidak boleh kosong"),
  relationship: Yup.string().required("Hubungan keluarga tidak boleh kosong"),
  dob: Yup.string().required("Tanggal lahir tidak boleh kosong"),
};

const INTERNATIONAL_SCHEMA = {
  name: Yup.string()
    .required("Nama tidak boleh kosong")
    .max(60, "Nama maksimal 60 karakter")
    .nullable(),
  passport_no: Yup.string()
    .matches(
      /^[a-zA-Z0-9-]+$/,
      "Paspor hanya boleh berisi huruf, angka, dan tanda hubung"
    )
    .max(9, "Passport maksimal 9 karakter")
    .required("Pasar tidak boleh kosong"),
  gender: Yup.string().required("Jenis Kelamin tidak boleh kosong"),
  relationship: Yup.string().required("Hubungan keluarga tidak boleh kosong"),
  dob: Yup.string().required("Tanggal lahir tidak boleh kosong"),
};

const NO_SPOUSE_RELATIONSHIP_OPTIONS = [
  {
    value: "child",
    display_name: "Anak",
  },
  {
    value: "spouse",
    display_name: "Pasangan",
  },
];

const RELATIONSHIP_OPTIONS = [
  {
    value: "child",
    display_name: "Anak",
  },
  {
    value: "spouse",
    display_name: "Pasangan",
  },
];

const InsuredForm = ({
  isInternationalTravel,
  payload,
  insuredIdx,
  isFamilyPackage,
  onFormSubmit,
  submitForm,
  submitArray,
  setSubmitArray,
  holderGender,
  setHasSpouse,
  hasSpouse,
  setSpouseIdx,
  spouseIdx,
  setChildCounter,
  childCounter,
  setChildArray,
  childArray,
  currForm,
  isMobile = false
}) => {
  const [formValues, setFormValues] = useState(Object.keys(get(payload, `insuredPerson[${insuredIdx}]`)).length ? get(payload, `insuredPerson[${insuredIdx}]`) : INTIIAL_VALUES);
  const [childSelected, setChildSelected] = useState(false);
  const [relationshipOption, setRelationshipOption] =
    useState(RELATIONSHIP_OPTIONS);
  const [formSchema, setFormSchema] = useState(
    isInternationalTravel ? INTERNATIONAL_SCHEMA : DOMESTIC_INDONESIAN_SCHEMA
  );
  const [showDOBModal, setShowDOBModal] = useState(false);
  const formRef = useRef();
  const [disabledBtn, setDisabledBtn] = useState('')

  useEffect(() => {
    setFormSchema(
      isInternationalTravel ? INTERNATIONAL_SCHEMA : DOMESTIC_INDONESIAN_SCHEMA
    );
  }, [isInternationalTravel]);

  useEffect(() => {
    if (
      formValues.citizenship &&
      formValues.citizenship === "Indonesian" &&
      !isInternationalTravel
    ) {
      setFormValues({ ...formValues, kitas_kitap: "" });
      const currSchema = { ...DOMESTIC_INDONESIAN_SCHEMA };
      if (childSelected) {
        delete currSchema.ktp;
        delete currSchema.kitas_kitap;
      }
      setFormSchema(currSchema);
    } else if (
      formValues.citizenship &&
      formValues.citizenship === "Foreigner" &&
      !isInternationalTravel
    ) {
      setFormValues({ ...formValues, ktp: "" });
      const currSchema = { ...DOMESTIC_NON_INDONESIAN_SCHEMA };
      if (childSelected) {
        delete currSchema.ktp;
        delete currSchema.kitas_kitap;
      }
      setFormSchema(currSchema);
    }
  }, [formValues.citizenship]);

  const dummySubmit = (_values, _formikbag) => {
    // dummy handle submit func
  };

  const handleChange = (values) => {
    if (values.relationship) {
      const currSchema = { ...formSchema };
      let updatedValues = { ...formValues };
      if (values.relationship === "child" && !isInternationalTravel) {
        setChildSelected(true);
        delete currSchema.kitas_kitap;
        delete currSchema.ktp;
        updatedValues = {
          ...updatedValues,
          ktp: "",
          kitas_kitap: "",
          passport_no: "",
          dob: "",
        };
        setFormValues(updatedValues);
        setFormSchema(currSchema);
      } else {
        setChildSelected(false);
        updatedValues = { ...updatedValues, dob: "" };
        setFormValues(updatedValues);
      }
    }
    setFormValues(values);
  };

  useEffect(() => {
    if (formValues.relationship === 'spouse') {
      setHasSpouse(true)
      setSpouseIdx(insuredIdx)
      if(holderGender && holderGender === 'male') {
        setFormValues({...formValues, gender: 'female', dob: ''})
        setDisabledBtn('male')
      } else {
        if (holderGender) {
          setFormValues({...formValues, gender: 'male', dob: ''})
          setDisabledBtn('female')
        }
      }
      if (childArray.includes(insuredIdx)) {
        const filterArray = childArray.filter(item => item !== insuredIdx)
        setChildArray(filterArray)
        setChildCounter(prev => prev - 1)
      }
    } else {
      if (spouseIdx === insuredIdx) {
        setHasSpouse(false)
        setSpouseIdx(null)
      }
      if (formValues.relationship === 'child') {
        setChildCounter(prev => prev + 1)
        childArray.push(insuredIdx)
      }
      setFormValues({...formValues, dob: ''})
      setDisabledBtn('')
    }
  }, [formValues.relationship, holderGender]);

  useEffect(() => {
    if (isMobile) {
      if (submitForm && insuredIdx === currForm - 1) {
        formRef.current.submitForm().then(() => {
          Yup.object(formSchema)
            .validate(formRef.current.values)
            .catch((err) => {
              console.error(err);
            })
            .then((values) => {
              if (values) {
                onFormSubmit(`insuredPerson[${insuredIdx}]`, values, "1");
                submitArray[insuredIdx + 1] = true;
                setSubmitArray(submitArray);
              }
            });
        });
      }
    }
    else {
        if (submitForm) {
        formRef.current.submitForm().then(() => {
          Yup.object(formSchema)
            .validate(formRef.current.values)
            .catch((err) => {
              console.error(err);
            })
            .then((values) => {
              if (values) {
                onFormSubmit(`insuredPerson[${insuredIdx}]`, values, "1");
                submitArray[insuredIdx + 1] = true;
                setSubmitArray(submitArray);
              }
            });
        });
      }
    }
  }, [submitForm]);


  useEffect(() => {
    setFormSchema(
      isInternationalTravel ? INTERNATIONAL_SCHEMA : DOMESTIC_INDONESIAN_SCHEMA
    );
  }, [isInternationalTravel]);

  useEffect(() => {
    const currSchema = { ...formSchema };
    if (!isFamilyPackage) {
      delete currSchema.relationship;
    }
    setFormSchema(currSchema);
  }, []);

  return (
    <InsuredFormWrapper>
      <HolderTitle>Wisatawan {insuredIdx + 2} </HolderTitle>
      <Formik
        onSubmit={dummySubmit}
        enableReinitialize
        initialValues={formValues}
        validationSchema={Yup.object(formSchema)}
        innerRef={formRef}
      >
        {(formProps) => (
          <>
            <FormHook values={formProps.values} onChange={handleChange} />
            <Title>Detail Personal</Title>
            <GridContainer>
              <TextInputV2
                label="Nama Lengkap"
                name="name"
                required
                showIcon={false}
                readonly={false}
              />
              <BooleanValue
                name="relationship"
                optionsList={relationshipOption}
                getter={(val) => val.value}
                renderer={(val) => val.display_name}
                disabledBtn={(hasSpouse && spouseIdx !== insuredIdx) ? 'spouse' : childCounter >= 3 ? 'child' : ''}
                showDisable
              />
            </GridContainer>
            <GridContainer>
              <BooleanValue
                name="gender"
                optionsList={[
                  {
                    display_name: "Laki-laki",
                    value: "male",
                  },
                  {
                    display_name: "Perempuan",
                    value: "female",
                  },
                ]}
                getter={(val) => val.value}
                renderer={(val) => val.display_name}
                disabledBtn={disabledBtn}
                showDisable
              />
              <TextInput
                name="dob"
                label="Tanggal Lahir"
                inputValue={
                  formProps.values.dob
                    ? format(formProps.values.dob, "dd/MM/yyyy")
                    : ""
                }
                icontype="date"
                onClick={() => setShowDOBModal(true)}
              />
            </GridContainer>
            <Title>Detail Identitas</Title>
            <GridContainer>
              {!isInternationalTravel && (
                <BooleanValue
                  name="citizenship"
                  optionsList={[
                    {
                      display_name: "WNI",
                      value: "Indonesian",
                    },
                    {
                      display_name: "WNA",
                      value: "Foreigner",
                    },
                  ]}
                  getter={(val) => val.value}
                  renderer={(val) => val.display_name}
                />
              )}
              {!childSelected ? (
                isInternationalTravel ? (
                  <TextInputV2
                    label="Nomor Paspor"
                    name="passport_no"
                    required
                    showIcon={false}
                    readonly={false}
                  />
                ) : formValues.citizenship === "Foreigner" ? (
                  <TextInputV2
                    label="Nomor KITAS/KITAP"
                    name="kitas_kitap"
                    required
                    showIcon={false}
                    readonly={false}
                  />
                ) : (
                  <TextInputV2
                    label="Nomor KTP"
                    name="ktp"
                    type="number"
                    required
                    showIcon={false}
                    readonly={false}
                  />
                )
              ) : null}
            </GridContainer>
            <Modal
              title={"Tanggal Lahir"}
              show={showDOBModal}
              onClose={() => setShowDOBModal(false)}
              maxWidth="500px"
            >
                <DateOfBirth
                  name="dob"
                  label="Tanggal Lahir"
                  minDate={subMonths(
                    new Date(payload?.holder?.package_request?.trip_start_date),
                    formValues.relationship === "child" ? 216 : 828
                  )}
                  maxDate={subDays(
                    subMonths(
                      new Date(
                        payload?.holder?.package_request?.trip_start_date
                      ),
                      formValues.relationship === "child" ||
                        formValues.relationship === "sibling"
                        ? 2
                        : 192
                    ),
                    1
                  )}
                  setFormValues={formProps.setValues}
                  formValues={formProps.values}
                  setShowDOBModal={setShowDOBModal}
                  tooltipText='Batas usia tertanggung untuk anak maks. 17 tahun'
                />
            </Modal>
          </>
        )}
      </Formik>
    </InsuredFormWrapper>
  );
};

export default InsuredForm;
