import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Dropdown from './components/Dropdown';
import {
  Container,
  MainHeading,
  SearchSection,
  Button,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  BannerContainer,
  BannerImageContainer,
  BannerImage,
  TableContainer,
  ContentInner,
  PaginationContainer,
  PaginationButtonLeft,
  PaginationButtonRight,
  HeadingSubtitle,
  WorkshopMobileContainer,
  WorkshopMobileHeader,
  WorkshopMobileType,
  WorkshopMobileInnerContainer,
  WorkshopMobileContent,
  DropdownMobileContainer,
  WorkshopButtonContainer,
  WorkshopButton,
} from './workshop.style';
import { imagesLink, strings} from './constants';
import { useGetWorkshopProvince, useGetWorkshopCityList, useGetWorkshopList } from '../../lib/api';
import ScrollToTop from '../../components/ScrollToTop';


const BengkelPage = () => {
  const { data, loading, error, getProvinceList } = useGetWorkshopProvince();
  const { data: cityData, loading: cityLoading, error: cityError, getCityList } = useGetWorkshopCityList();
  const { data: workshopData, loading: workshopLoading, error: workshopError, getWorkshopList, totalData, maxPage } = useGetWorkshopList();
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState('Indonesia');
  const [selectedWorkshop, setSelectedWorkshop] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 480);


  const provinceOptions = data && data.length > 0 ? data : [];
  const cityOptions = cityData && cityData.length > 0 ? cityData : [];
  const workshopOptions = workshopData && workshopData.length > 0 ? workshopData : [];

  const options = ['Authorized', 'Non Authorized', 'Premium Workshop'];

  useEffect(() => {
    getProvinceList();
    getWorkshopList('',currentPage,'');

  }, [getProvinceList,getWorkshopList,currentPage]);

  useEffect(() => {
    if(selectedWorkshop && selectedCity === "Indonesia") {
      getWorkshopList('', currentPage, selectedWorkshop)
    }
  },[selectedCity, selectedWorkshop, currentPage, getWorkshopList])

  useEffect(() => {
    if (selectedProvince) {
      getCityList(selectedProvince);
    }
  }, [selectedProvince, getCityList]);

  useEffect(() => {
    if (selectedCity && selectedCity !== 'Indonesia') {
      getWorkshopList(selectedCity, currentPage, selectedWorkshop);
    }
  }, [selectedCity, selectedWorkshop, currentPage, getWorkshopList]);

  useEffect(() => {
    setCurrentPage(1);
  },[selectedProvince,selectedCity])

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobileView(window.innerWidth <= 480);
    });
  },[isMobileView])

  const handleNextPage = () => {
    if (currentPage < maxPage) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };


  return (
    <Container>
      <Header />
      <MainHeading>{strings.bengkelHeading}</MainHeading>
      <HeadingSubtitle>Bengkel rekanan siap bantu setiap waktu.<br />Kendaraan kembali prima tanpa menunggu.</HeadingSubtitle>
      <BannerContainer>
        <BannerImageContainer>
          <BannerImage src={isMobileView ? imagesLink.heroImageMobile : imagesLink.heroImage} />
        </BannerImageContainer>
      </BannerContainer>
      <ContentInner>
        {isMobileView ? 
          <SearchSection>
            <DropdownMobileContainer>
              {loading ? (
                <p>Loading provinces...</p>
              ) : error ? (
                <p>Error loading provinces: {error}</p>
              ) : (
                <Dropdown
                  options={provinceOptions}
                  placeholder="Provinsi"
                  placeholderIcon={imagesLink.placeholderLocation}
                  onSelect={setSelectedProvince}
                  isDisabled={loading || error}
                />
              )}
              {cityLoading ? (
                <p>Loading City...</p>
              ) : cityError ? (
                <p>Error loading City:</p>
              ) : (
                <Dropdown
                  options={cityOptions}
                  placeholder="Kota"
                  isDisabled={!selectedProvince || loading || error}
                  placeholderIcon={imagesLink.placeholderLocation}
                  onSelect={setSelectedCity}
                />
              )}
            </DropdownMobileContainer>
            <WorkshopButtonContainer>
              {options?.map((item,index) => (
                <WorkshopButton key={index} className={`${selectedWorkshop === item ? 'active' : ''}`} onClick={(() => setSelectedWorkshop(item))}>{item === 'Premium Workshop' ? "Premium" : item}</WorkshopButton>
              ))}
            </WorkshopButtonContainer>
          </SearchSection> 
        : (
          <SearchSection>
            {loading ? (
              <p>Loading provinces...</p>
            ) : error ? (
              <p>Error loading provinces: {error}</p>
            ) : (
              <Dropdown
                options={provinceOptions}
                placeholder="Provinsi"
                placeholderIcon={imagesLink.placeholderLocation}
                onSelect={setSelectedProvince}
                isDisabled={loading || error}
              />
            )}
            {cityLoading ? (
              <p>Loading City...</p>
            ) : cityError ? (
              <p>Error loading City:</p>
            ) : (
              <Dropdown
                options={cityOptions}
                placeholder="Kota"
                isDisabled={!selectedProvince || loading || error}
                placeholderIcon={imagesLink.placeholderLocation}
                onSelect={setSelectedCity}
              />
            )}
              {
                <Dropdown
                  options={options}
                  placeholder="Jenis Bengkel"
                  isDisabled={loading || error}
                  placeholderIcon={imagesLink.workshop}
                  onSelect={setSelectedWorkshop}
                />
              }
          </SearchSection>
        )}

        {workshopOptions && workshopOptions.length > 0 ? (
          <>
            <h2 className='table-title'>{totalData} Bengkel Rekanan <span className='text-normal'>di</span> {selectedCity}</h2>
            {isMobileView ? (
                workshopOptions.map((workshop, index) => (
                  <WorkshopMobileContainer key={index} style={{ background: index % 2 !== 0 ? '#EFEFEF' : '#fff' }}>
                    <WorkshopMobileInnerContainer>
                      <WorkshopMobileHeader>
                        <h3 className='workshop-name'>{workshop.name}</h3>
                        <WorkshopMobileType>
                          {workshop.type}
                        </WorkshopMobileType>
                      </WorkshopMobileHeader>
                      <WorkshopMobileContent>
                        <p>{workshop.province}</p>
                        <p>{workshop.city}</p>
                        <img src={imagesLink.locationIcon} alt="location" onClick={() =>window.open(workshop.location_link, '_blanks')}/>
                      </WorkshopMobileContent>
                    </WorkshopMobileInnerContainer>
                  </WorkshopMobileContainer>
                ))
            ) : (
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <TableHeader>{strings.tableHeaders.name}</TableHeader>
                      <TableHeader>{strings.tableHeaders.type}</TableHeader>
                      <TableHeader>{strings.tableHeaders.region}</TableHeader>
                      <TableHeader>{strings.tableHeaders.city}</TableHeader>
                      <TableHeader>{strings.tableHeaders.location}</TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {workshopOptions.map((workshop, index) => (

                      <TableRow key={index}>
                        <TableCell>{workshop.name}</TableCell>
                        <TableCell>{workshop.type}</TableCell>
                        <TableCell>{workshop.province}</TableCell>
                        <TableCell>{workshop.city}</TableCell>
                        <TableCell>
                          <img src={imagesLink.locationIcon} alt="location" onClick={() =>window.open(workshop.location_link, '_blanks')}/>
                        </TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
            )}
          </>
        ) : (
          <p>No workshops available for the selected city. Please adjust your filters or try again later.</p>
        )}
        <PaginationContainer>
          <PaginationButtonLeft onClick={handlePrevPage} disabled={currentPage === 1}>
            <img src={imagesLink.arrowRightWhite}/>
          </PaginationButtonLeft>
            {currentPage}
          <PaginationButtonRight onClick={handleNextPage} disabled={currentPage === maxPage}>
            <img src={imagesLink.arrowRightWhite}/>
          </PaginationButtonRight>
        </PaginationContainer>
      </ContentInner>
      <ScrollToTop />
      <Footer />
    </Container>
  );
};

export default BengkelPage;

