import { ImageUrl } from "../Images";

export const PRODUCT_INFORMATION = {
    'individual': {
      title: "Individual",
      para1:
        "Pilih opsi ini apabila Kamu hanya ingin mengasuransikan diri sendiri dalam perjalanan.",
    },
    'group': {
      title: "Grup",
      para1:
        "Pilih opsi ini apabila Anda melakukan perjalanan bersama dengan kerabat atau teman dengan minimal 2 orang dewasa dan maksimal 10 orang dewasa (lebih dari 17 tahun).",
    },
    'family': {
      title: "Keluarga",
      para1:
        "Pilih opsi ini apabila kamu melakukan perjalanan bersama dengan keluarga kamu. Polis ini akan menjamin Anda, Pasangan sah Anda, dan Anak-anak Anda yang berpergian ke tempat tujuan yang sama dan dalam jangka waktu yang sama. Jumlah minimum anggota keluarga yang bepergian adalah 2 (dua) dan jumlah maksimum anak yang dapat dijamin adalah 3 (tiga).",
      para2:
        "Batas manfaat yang diberikan adalah sesuai dengan rencana manfaat yang Kamu pilih dan tercantum dalam Polis Asuransi.",
      para3:
        "Total nilai manfaat keseluruhan yang Anda dapatkan adalah 200% dari batas yang tercantum pada Polis Asuransi Anda untuk setiap perjalanan.",
    },
  };

export const PRODUCT_LIST = [
  {
    key: 'auto',
    inactiveIcon: ImageUrl.Auto_Inactive,
    activeIcon: ImageUrl.Auto_active,
    label: 'Auto',
    formType: 'other',
    productIcon: ImageUrl.AutoIcon,
    productIconMob: ImageUrl.AutoIcon,
    backgroundImg: ImageUrl.AutoBanner,
    backgroundImgMob: ImageUrl.AutoBannerMob,
    productName:'Asuransi Auto',
    textColor: '#000000',
    formBtnColor: '#00D278',
    formBtnText: 'Cari Tau Tentang Asuransi Auto',
    header: 'Perjalanan Aman Dimulai dengan Asuransi Kendaraan Bermotor Terbaik',
    url: '/produk-auto',
    description: 'Asuransi Auto TAP Insure memberikan perlindungan komprehensif yang melindungi kendaraan kamu dari berbagai risiko, mulai dari kerusakan ringan hingga kehilangan total.'
  },
  {
    key: 'travel',
    inactiveIcon: ImageUrl.Travel_Inactive,
    activeIcon: ImageUrl.Travel_active,
    label: 'Travel',
    formType: 'travel',
    productName:'Asuransi Travel',
    productIcon: ImageUrl.TravelIcon,
    productIconMob: ImageUrl.TravelIcon,
    backgroundImg: ImageUrl.TravelBanner,
    backgroundImgMob: ImageUrl.TravelBannerMob,
    textColor: '#000000',
    formBtnColor: '#B43CFA',
    formBtnText: 'Beli Asuransi Travel',
    header: 'Teman Setia Kamu untuk Petualangan Aman dan Mengesankan!',
    url: '/produk-travel',
    description: 'Asuransi Travel melindungi perjalanan Kamu dari awal hingga kembali dengan aman, sehingga Kamu bisa menikmati perjalanan tanpa khawatir.'
  },
  {
    key: 'warranty',
    inactiveIcon: ImageUrl.Warranty_Inactive,
    activeIcon: ImageUrl.Warranty_active,
    label: 'Extended Warranty',
    formType: 'other',
    productName:'Asuransi Extended Warranty',
    productIcon: ImageUrl.WarrantyIcon,
    productIconMob: ImageUrl.EWLogo,
    backgroundImg: ImageUrl.WarrantyBanner,
    backgroundImgMob: ImageUrl.WarrantyBannerMob,
    textColor: '#FFFFFF',
    formBtnColor: '#64B4AA',
    formBtnText: 'Cari Tau Tentang Asuransi Extended Warranty',
    header: 'Tingkatkan Keamanan Kendaraanmu dengan Perlindungan Ekstra!',
    url: '/produk-extended-warranty',
    description: 'Asuransi Warranty TAP Insure memberikan perlindungan menyeluruh untuk kendaraan Kamu. Nikmati ketenangan pikiran saat berkendara, karena kami siap melindungi kendaraan Kamu!'
    
  },
  {
    key: 'safe',
    inactiveIcon: ImageUrl.Safe_Inactive,
    activeIcon: ImageUrl.Safe_active,
    label: 'Safe',
    formType: 'other',
    productName:'Asuransi Safe',
    productIcon: ImageUrl.SafeIcon,
    productIconMob: ImageUrl.SafeLogo,
    backgroundImg: ImageUrl.SafeBanner,
    backgroundImgMob: ImageUrl.SafeBannerMob,
    textColor: '#FFFFFF',
    formBtnColor: '#FA3C46',
    formBtnText: 'Cari Tau Tentang Asuransi Safe',
    header: 'Diri Kamu dan Keluarga adalah Prioritas Utama dalam Perlindungan',
    url: '/produk-safe',
    description: 'Asuransi Safe TAP Insure, memberikan perlindungan untuk biaya pengobatan jika Kamu mengalami kecelakaan, dan manfaat finansial jika kecelakaan tersebut menyebabkan cacat atau kematian. '
    
  },
  {
    key: 'device',
    inactiveIcon: ImageUrl.Device_Inactive,
    activeIcon: ImageUrl.Device_active,
    label: 'Device',
    formType: 'other',
    productName:'Asuransi Device',
    productIcon: ImageUrl.DeviceIcon,
    productIconMob: ImageUrl.DeviceLogo,
    backgroundImg: ImageUrl.DeviceBanner,
    backgroundImgMob: ImageUrl.DeviceBannerMob,
    textColor: '#000000',
    formBtnColor: '#00B4FA',
    formBtnText: 'Cari Tau Tentang Asuransi Device',
    header: 'Gadget Aman, Hidup Nyaman!',
    url: '/produk-device',
    description: 'Asuransi Device TAP Insure melindungi barang-barang berhargamu seperti smartphone, tablet, laptop, smart TV, dan gadget lainnya dari risiko tak terduga, seperti kerusakan, kehilangan, atau pencurian.'
    
  },
  {
    key: 'property',
    inactiveIcon: ImageUrl.Property_Inactive,
    activeIcon: ImageUrl.Property_active,
    label: 'Property',
    formType: 'other',
    productName:'Asuransi Property',
    productIcon: ImageUrl.PropertyIcon,
    productIconMob: ImageUrl.PropertyIcon,
    backgroundImg: ImageUrl.PropertyBanner,
    backgroundImgMob: ImageUrl.PropertyBannerMob,
    textColor: '#000000',
    formBtnColor: '#FA64C8',
    formBtnText: 'Cari Tau Tentang Asuransi Property',
    header: 'Perlindungan Tanpa Batas untuk Harta Berharga Kamu',
    url: '/produk-property',
    description: 'Asuransi Property TAP Insure memberikan perlindungan menyeluruh terhadap harta benda Kamu dari berbagai risiko. Lindungi aset Kamu dengan jaminan yang terpercaya!'
  },
  {
    key: 'lainnya',
    inactiveIcon: ImageUrl.Other_Inactive,
    activeIcon: ImageUrl.Other_active,
    label: 'Lainnya',
    formType: 'other',
    productName:'',
    productIcon: '',
    productIconMob: ImageUrl.OthersIcon,
    backgroundImg: ImageUrl.OthersBanner,
    backgroundImgMob: ImageUrl.OthersBannerMob,
    textColor: '#FFFFFF',
    formBtnColor: '#333333',
    formBtnText: 'Cari Tau Tentang Asuransi Lainnya',
    header: 'Solusi Perlindungan untuk Segala Kebutuhan',
    url: '/produk-lainnya',
    description: 'Beragam perlindungan menyeluruh dari TAP Insure hadir untuk memberikan rasa aman di setiap langkah. Siap menghadapi risiko, kapan saja, di mana saja, dengan solusi fleksibel yang terus berkembang.'
  },
]