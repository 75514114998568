import styled from "styled-components";
import LargeButton from "../../components/LargeButton";

export const PaymnetSucessFullWarpper = styled.div`
  width: 100%;
  background: url("https://storage.googleapis.com/pp_img/tap-travel-website/common/Bottom-Vector.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-color: #f5f5f5;
  background-position: bottom;
`;

export const PaymentTopImageCtn = styled.div`
  position: relative;
  background: url(${props => props.banner ? props.banner : ''});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  height: 55vh;
  max-height: 480px;

  @media screen and (max-width: 1024px) {
    height: 35vh;
  }
`;
export const ContentWrapper = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    width: 92%;
  }
`;
export const MiddleIconCtn = styled.div`
  margin-top: -64px;
  width: 80%;
  position: absolute;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    margin-top: -32px;
  }
`;
export const MiddleIconCtn2 = styled.div`
  margin-top: -64px;
  width: 80%;
  position: absolute;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    margin-top: -32px;
  }
`;
export const DetailsWrapper = styled.div`
  width: 80%;
  margin: auto;

  @media screen and (max-width: 1024px) {
    width: 92%;
  }
`;
export const PolicyNumberCtn = styled.div`
  width: calc(100% - 78px);
  padding: 32px 32px;
  border-radius: 16px;
  opacity: 0px;
  text-align: center;
  font-family: Mulish;
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  background: #ffffff;
  margin-bottom: 16px;
  margin-top: 20px;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    padding: 20px;
    width: calc(100% - 40px);
  }
`;
export const DetailsTabCtn = styled.div`
  display: grid;
  grid-template-columns:  24% 24% 24% 24%;
  gap: 1%;
  margin-bottom: 64px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 49% 49%;
    margin-bottom: 32px;
  }
`;
export const DetailsTabCtn2 = styled.div`
  display: grid;
  grid-template-columns: 24% 24%;
  gap: 1%;
  margin-bottom: 64px;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 49% 49%;
    margin-bottom: 32px;
  }
`;
export const DetailsTabCtnExpired = styled.div`
  display: grid;
  grid-template-columns: 24% 24%;
  gap: 1%;
  margin-bottom: 64px;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    margin-bottom: 32px;
  }
`;
export const DetailsTab = styled.div`
  padding: 32px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background: #ffffff;

  @media screen and (max-width: 1024px) {
    padding: 16px;
    gap: 4px;
  }
`;
export const DetailsTabHeader = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #999999;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
  }
`;
export const DetailsTabValue = styled.div`
  font-family: Mulish;
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  color: #333333;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
  }
`;
export const BottomText = styled.div`
  font-family: Mulish;
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
  text-align: center;
  width: 60%;
  margin: auto;
  margin-bottom: 16px;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
    width: 92%;
    line-height: 20px;
  }
`;
export const PlayStoreIconCtn = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 64px;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    margin-bottom: 32px;
  }
`;
export const PageHeader = styled.div`
  font-family: Mulish;
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : "#333333")};
  margin: 80px 0px 20px 0px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
    margin: 50px 0px 20px 0px;
  }
`;
export const DetailsText = styled.div`
  font-family: Mulish;
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
  text-align: center;
  margin-bottom: 30px;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
    line-height: 26px;
  }
`;
export const PaymentBoldText = styled.span`
  font-family: Mulish;
  font-size: 18px;
  font-weight: 800;
  line-height: 34px;
  text-align: center;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 26px;
  }
`;
export const DocumentBtn = styled(LargeButton)`
    width: 300px;
    margin: 30px auto 64px auto; 

  @media screen and (max-width: 1024px) {
    padding: 12px 24px;
    margin: 30px auto 32px auto; 
  }
`
export const DocumentBtn3 = styled.div`
  margin-top: 10px;
  padding: 6px 24px 6px 24px;
  border-radius: 12px;
  background: #B43CFA;
  color: #ffffff;
  font-size: 14px;
  transition: transform 0.2s ease;
  font-weight: 800;

  &:hover {
    transform: scale(0.97); 
  }

  @media screen and (max-width: 1024px) {
    padding: 12px 24px;
    margin: 30px auto 32px auto; 
  }
`
export const DocumentBtn2 = styled(LargeButton)`
    width: 300px;
    margin: 30px 0px 64px 0px; 

  @media screen and (max-width: 1024px) {
    padding: 12px 24px;
    margin: 10px auto 32px auto; 
  }
`
export const FooterTextCtn = styled.div`
    font-family: Mulish;
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: left;
    padding: 5px;

  @media screen and (max-width: 1024px) {
    font-size: 10px;
    gap: 4px;
  }
`
export const AnchorTag = styled.a`
    text-decoration: none;
`
export const BounceLoaderCtn = styled.div`
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: url("https://storage.googleapis.com/pp_img/tap-travel-website/common/Bottom-Vector.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-color: #f5f5f5;
  background-position: bottom;
`
export const FlexRowCtn = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
`
export const PolicyNumberWrapper = styled.div`
  padding-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
`
export const PolicyNumberCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    border-right: ${props => props.noBorder ? '' : '1.5px solid #EEEEEE'};
  }
`
export const GreyHr = styled.div`
  width: 100%;
  height: 1.5px;
  background: #EEEEEE;
  margin: 32px 0px 0px 0px;
`
export const TermsA = styled.a`
  font-family: 'Mulish';
  font-weight: 800;
  font-size: 0.9rem;
  line-height: 2rem;
  color: #333333;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 800;
  line-height: 34px;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    font-size: 10px;
    line-height: 17px;
  }
`
export const StatusImage = styled.img`
  width: 128px;
  height: 128px;

  @media screen and (max-width: 1024px) {
    width: 64px;
    height: 64px;
  }
`
export const Icons = styled.img`
  width: 156px;
  height: 56px;

  @media screen and (max-width: 1024px) {
    width: 104px;
    height: 32px;
  }
`
export const InfoIcon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 10px;

  @media screen and (max-width: 1024px) {
    width: 17px;
    height: 17px;
  }
`
export const FooterTextWrap = styled.div`
  line-height: 17px;
  width: 100%;

  @media screen and (max-width: 1024px) {
    width: 60%;
  }
`