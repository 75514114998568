import React from "react";
import { ModalWrapper } from "./index.style";

const Modal = ({ show, onClose, children, title, maxWidth = '1024px', showFooter = false, footer, showHeader = true }) => {

  if (!show) {
    return null;
  }

  return (
    <ModalWrapper maxWidth={maxWidth}>
      <div className="modal__overlay">
        <div className="modal__main">
          {showHeader && <div className="modal__header">
            <div className="modal__title">{title}</div>
            <button className="modal__close" onClick={onClose}>
              &times;
            </button>
          </div>}
          <div className="modal__content">{children}</div>
          {
            showFooter && <div className="modal__footer">{footer}</div>
          }
        </div>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
