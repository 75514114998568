import styled from "styled-components";

export const TravelHeaderCTN = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 20px 0px;
  border-bottom: 2px solid #f5f5f5;
  position: sticky;
  top: 0px;
  z-index: 10;

  @media screen and (max-width: 1024px) {
    padding: 16px 0px;
  }
`;
export const TravelHeaderWrapper = styled.div`
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: 20% 50%;
  gap: 30%;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px) {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const ImageTag = styled.img`
  cursor: pointer;
`
export const HeaderRouteCtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
export const HeaderRouteCtnMobile = styled.div`
 display: none;

  @media screen and (max-width: 1024px) {
    display: block;
  }
`;
export const HeaderTitle = styled.div`
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
`;
export const HeaderRouteWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  gap: 34px;
  justify-content: end;
`;
export const PoliciesInfoCtn = styled.div`
  padding: 16px;

  @media screen and (max-width: 1024px) {
    padding: 0px;
  }
`;
export const Title = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  margin-bottom: 16px;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
  }
`
export const HamburgerWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  top: 0px;
  z-index: 100;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
`
export const HamburgerCtn = styled.div`
  background: white;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  width: 100%;
  height: fit-content;
  overflow: auto;
`
export const HamburgerTop = styled.div`
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #F5F5F5;
`
export const HamburgerListCtn = styled.div`
  padding: 32px 32px;
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 4%;
`
export const HambergerMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
`
export const TravelHeaderTab = styled.div`
  padding: 20px;

  @media screen and (max-width: 1024px) {
    padding: 16px;
  }
`