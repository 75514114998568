import React from 'react'
import { HomePageContainer, DownloadButton, DownloadButtons, DownalodIcon } from './HomePage.style'
import { imagesLink, strings } from './constants'
import Header from '../../components/Header'
import CardSlider from '../../components/CardSlider'
import Footer from '../../components/Footer'
import ScrollToTop from '../../components/ScrollToTop'
import UserCriteria from '../../containers/userCriteria'
import { commonUrls } from '../../constants/urls'

const HomePage = () => {
  return (
    <HomePageContainer>
        <Header />
        <UserCriteria />
        <div className='hero-section'>
          <h1><strong>Yang Beda</strong> dari <strong>TAP Insure</strong></h1>
          <img src={imagesLink.tapInsureAdvantage} className='banner-asset'/>
          <div className='hero-items'>
            <h2>Terjangkau</h2>
            <p>Tidak menguras anggaran kamu</p>
          </div>
          <div className='hero-items'>
            <h2>Cepat</h2>
            <p>Klaim cepat untuk memastikan kamu mendapatkan manfaatnya secepat mungkin.</p>
          </div>
          <div className='hero-items'>
            <h2>Simpel</h2>
            <p>Proses asuransi mudah dimengerti dan tidak rumit.</p>
          </div>
        </div>
        <div className='product'>
          <h1>Temukan yang Sesuai dengan Kebutuhan Kamu</h1>
          <CardSlider />
        </div>
        <div className='hero-section'>
          <h1 className='hero-section-title-2'><strong>TAP Insure, jawaban semua kebutuhanmu!</strong></h1>
          <p className='hero-subtitle'>Solusi perlindungan yang tepat kini ada di genggamanmu. Unduh sekarang dan rasakan hidup lebih tenang!</p>
          <DownloadButtons>
            <DownloadButton href={commonUrls.appStore} target='_blank'>
              <DownalodIcon src={imagesLink.appleIcon} />
              <div className='text-container'>
                <p className='download-text'>{strings.downloadText}</p>
                <p>{strings.appStore}</p>
              </div>
            </DownloadButton>
            <DownloadButton href={commonUrls.playStore} target='_blank'>
              <DownalodIcon src={imagesLink.playStoreIcon} />
              <div className='text-container
            '>
                <p className='download-text'>{strings.foundOn}</p>
                <p>
                  {strings.playStore}
                </p>
              </div>
            </DownloadButton>
        </DownloadButtons>
          <img src={imagesLink.bannerBottom} className='banner-footer'/>
        </div>
        <ScrollToTop />
        <Footer />
    </HomePageContainer>
  )
}

export default HomePage
