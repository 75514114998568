import styled from 'styled-components'

export const FooterContainer = styled.div`
    font-family: "Mulish", sans-serif;
    background: #FFFFFF;
    margin-top: 30px;

    @media only screen and (max-width: 480px) {
        border-top: 2px solid #EEEEEE;
    }

    .inner-container {
        padding: 0;
    }

    .inner-container h1 {
        margin: 0;
        padding: 0;
    }

    .footer-top {
        display: grid;
        grid-template-columns: 1.5fr 1fr 1fr 1.5fr 1.5fr;
        padding: 16px;
    }

    .footer-top div:nth-child(1) p {
        font-size: 12px;
        line-height: 24px;
        text-align: left;
    }

    .footer-top-items {
        border-bottom: 2px solid #FAF000;
        padding: 16px;
    }

    .footer-top-items h2 {
        color: #333;
        font-weight: 800;
        margin: 0;
        padding: 0;
        font-size: 14px;
        text-align: left;
    }

    .footer-top-items:nth-child(1) {
        padding-right: 16px;
        display: flex;
        flex-direction: column;
    }

    .footer-top-items:not(:nth-child(1)) {
        border-left: 2px solid #FAF000;
        padding-left: 16px;
    }

    .footer-top img {
        width: 156px;
    }

    .aus-mariberasuransi {
        display: flex;
        gap: 15px;
        align-items: center;
    }

    .aus-mariberasuransi img{
        width: 52px;
    }

    .socmed {
        display: flex;
        gap: 16px;
        margin-top: 64px;
        margin-bottom: 64px;
        cursor: pointer;
    }

    .socmed img {
        width: 32px;
    }

    .footer-top-items ul {
        margin: 0;
        padding-left: 0;
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .footer-top-items ul li {
        list-style: none;
        text-align: left;
    }

    .footer-top-items ul li a {
        text-decoration: none;
        color: #000;
        font-size: 14px;
    }

    .clickable {
        cursor: pointer;
        transition: font-weight 0.5s ease;

        &:hover {
            font-weight: 700;
        }
    }

    .footer-with-logo {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .footer-with-logo img {
        width: 24px;
    }

    .footer-bottom {
        margin-bottom: 0;
        padding-bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
        font-size: 14px;
        font-weight: 500;
        color: #333;
    }

    .footer-bottom a {
        text-decoration: underline;
        cursor: pointer;
    }

    .privacy-policy {
        display: flex;
        gap: 24px;
    }

    .privacy-policy a {
        color: #000;
    }

    .arrow {
        display: none;
    }

    .underline-text {
        text-decoration: underline;
    }

    .aus-desc {
        max-width: 80%;
    }

    @media (max-width: 1024px) {
        .footer-top {
            grid-template-columns: 1fr 1fr;
            gap: 16px;
        }

        .footer-top-items {
            padding: 16px 8px;
            border: none;
        }
        .footer-top-items:not(:nth-child(1)) {
            border-left: none;
        }
    }

    @media (max-width: 768px) {
        .footer-top {
            grid-template-columns: 1fr;
        }

        .footer-top-items {
            padding: 0 0 16px 0;
            border-left: none;
            border-bottom: 2px solid #FAF000;
        }

        .footer-top-items:not(:nth-child(1)) {
            border-left: none;
        }

        .footer-bottom {
            flex-direction: column;
            padding: 16px;
            gap: 8px;
            font-size: 12px;
        }

        .footer-bottom p{
            margin: 0;
            padding: 0;
        }

        .footer-top-items {
            font-size: 12px;
        }

        .footer-top-items ul {
            margin: 0;
            padding-left: 0;
            padding-top: 0;
            display: flex;
            flex-direction: column;
            gap: 24px;
            overflow: hidden;
            max-height: 0;
            transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
        }

        .footer-top-items.open ul {
            padding-top: 24px;
            max-height: 500px;
            transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
        }

        .arrow {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            padding: 0;
        }

        .arrow img {
            width: 12px; /* Adjust the size of the arrow image */
            height: 12px;
            transition: transform 0.5s ease;
        }

        .footer-top-items.open .arrow img {
            transform: rotate(180deg);
        }

        .footer-top-items h2 {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
    }

    @media (max-width: 480px) {
        .footer-top-items {
            align-items: center;
        }
        .footer-top-items .tap-insure-logo {
            position: relative;
            left: 10%;
        }
        .socmed {
            gap: 12px;
            margin-top: 32px;
            margin-bottom: 32px;
        }
        .aus-desc {
            max-width: 85%;
        }
        .footer-top div:nth-child(1) p {
            text-align: center;
        }

    }
`
