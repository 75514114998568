import React from "react";
import { getWindowDimensions } from "../../constants/travel";
import TravelForms from './travelform'
import TravelFormsMobile from './travelformMobile'

const TravelForm = () => {
  const isMobile = getWindowDimensions().width < 1024

  return (
    isMobile ? <TravelFormsMobile /> : <TravelForms />
  )
}

export default TravelForm