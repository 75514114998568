import { useEffect, useState } from "react"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import {
  body1List,
  body2ListContentTowTruck,
  body2ListContentOnsiteAccident,
  body3ListContent,
  imagesLink,
  langStrings,
  riplayDocUrl,
} from "./constants"
import {
  BannerContainer,
  BannerContent,
  BannerForegroundImage,
  BannerForegroundImageContainer,
  BannerSubTitle,
  BannerTitle,
  Body1Container,
  Body1ContainerList,
  Body1Content,
  Body1Heading,
  Body1InfoContainerOrderedList,
  Body1SideImage,
  Body1SideImageContainer,
  Body1UnorderedList,
  Body1UnorderedListContainer,
  Body1ContainerListMobile,
  Body2Container,
  Body2HeadingText,
  Body2ImageContainer,
  Body2ImageHolder,
  Body2ImagesContainer,
  Body2OrderedList,
  Body2OrderedListContainer,
  Body2OrderedListHeading,
  Body2OrderedListText,
  Body3Container,
  Body3Content,
  Body3ImageContainer,
  Body3ImageHeading,
  Body3ImageHolder,
  Body3ListContainer,
  Body3ListItem,
  Body4BannerImage,
  Body4Button,
  Body4ButtonContainer,
  Body4ButtonImage,
  Body4Container,
  Body4Heading,
  Body4ImageContainer,
  Body4SubTitle,
  Body4TextContainer,
  Body5Container,
  Body5TextContainer,
  Body5Heading,
  Body5SubTitle,
  BodyContainer,
  InquiryFormDiv,
  HeadingSubtitle,
  AnchorTag,
  GreenShield,
  Body1ContainerMobileItems,
} from "./roadAssistance.style"
import InquiryForm from "../userCriteria/inquiryForm"
import ScrollToTop from "../../components/ScrollToTop"
import { ImageUrl } from "../../Images"
import CustomSlider from "../../components/CustomSlider"

function RoadAssistance(props) {
  const [choosenOption, setChoosenOption] = useState(0)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 480);
  const body2ListContent = choosenOption ? body2ListContentTowTruck : body2ListContentOnsiteAccident

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobileView(window.innerWidth <= 480);
    });
  },[isMobileView])

  return (
    <div>
      <Header />
      <BannerContainer>
        <BannerSubTitle>
        <img src = {imagesLink.autoText}/>
        </BannerSubTitle>
        <BannerTitle>
          {langStrings.roadAssistance}
        </BannerTitle>
        <HeadingSubtitle>Berkendara dengan Tap Insure aman dan nyaman,<br/>Bantuan selalu ada, kapan pun dibutuhkan.</HeadingSubtitle>


        <BannerContent>
          <BannerForegroundImageContainer>
            <BannerForegroundImage src={isMobileView ? imagesLink.roadAssistanceBannerMobile : imagesLink.roadAssistanceBannerImage} />
          </BannerForegroundImageContainer>
        </BannerContent>

      </BannerContainer>
      <BodyContainer>
        <Body1Container>
          <Body1Heading>
            <h1>Simpelnya, ini yang harus kamu lakukan saat kejadian darurat atau mobil rusak pas di jalan</h1>
          </Body1Heading>
          <Body1Content>
            <Body1SideImageContainer>
              <Body1SideImage
                src={imagesLink.manTryingToRepair}
              />
            </Body1SideImageContainer>
            {isMobileView ? (
              <CustomSlider slidesLength={body1List.length}>
                {body1List.map(({ text, children = [] }, index) => (
                  <Body1ContainerListMobile key={index}>
                    <Body1ContainerMobileItems>
                      <GreenShield src={imagesLink.greenShield} />
                      <p className="body1SliderHead">{String(index + 1).padStart(2, '0')}</p>
                      <span className="body1SliderText" dangerouslySetInnerHTML={{ __html: text }} />
                      <Body1UnorderedListContainer>
                        {children.map(({ text }, childIndex) => (
                          <Body1UnorderedList key={childIndex}>
                            {text}
                          </Body1UnorderedList>
                        ))}
                      </Body1UnorderedListContainer>
                    </Body1ContainerMobileItems>
                  </Body1ContainerListMobile>
                ))}
              </CustomSlider>
            ) : (
              <>
                <Body1InfoContainerOrderedList>
                  {body1List.map(({ text, children = [] }, index) => (
                    <Body1ContainerList key={index}>
                      <span dangerouslySetInnerHTML={{ __html: text }} />
                      <Body1UnorderedListContainer>
                        {children.map(({ text }, childIndex) => (
                          <Body1UnorderedList key={childIndex}>
                            {text}
                          </Body1UnorderedList>
                        ))}
                      </Body1UnorderedListContainer>
                    </Body1ContainerList>
                  ))}
                </Body1InfoContainerOrderedList>
              </>
            )}
          </Body1Content>

        </Body1Container>

        <Body2Container>
          <Body2HeadingText>Beragam Layanan <span className="text-italic">Roadside Assistance</span> yang Siap Membantumu!</Body2HeadingText>
          <Body2ImagesContainer>
            <Body2ImageContainer onClick={()=> setChoosenOption(0)} active={choosenOption === 0} >
              <Body2ImageHolder src={imagesLink.towTruck} />
            </Body2ImageContainer>
            <Body2ImageContainer onClick={()=> setChoosenOption(1)} active={choosenOption === 1}>
              <Body2ImageHolder src={imagesLink.manCallingAfterAccident} />
            </Body2ImageContainer>
          </Body2ImagesContainer>
          {isMobileView ? (
            <div style={{maxWidth: "100vw"}}>
              <CustomSlider slidesLength={body2ListContent.length}>
                {body2ListContent.map(({ heading, text }, index) => (
                  <Body1ContainerListMobile key={index}>
                        <Body1ContainerMobileItems>
                          <GreenShield src={imagesLink.greenShield} />
                          <p className="body1SliderHead">
                            {String(index + 1).padStart(2, '0')} <br/>
                            {heading}
                          </p>
                          <span className="body1SliderHead"></span>
                          {text.map((t) => {
                            return (
                              <span className="body1SliderText">
                                {t}
                              </span>
                            )
                           })}
                          {index > 0 && <br />}
                        </Body1ContainerMobileItems>
                      </Body1ContainerListMobile>
                  ))}
               </CustomSlider>
            </div>
          ) : (
            <Body2OrderedListContainer>
              {
                body2ListContent.map(({ heading, text }) => {
                  return (
                    <Body2OrderedList>
                      <Body2OrderedListHeading>
                        {heading}
                      </Body2OrderedListHeading>
                      {text.map((t) => {
                        return (
                          <Body2OrderedListText>
                            {t}
                          </Body2OrderedListText>
                        )
                      })}
                    </Body2OrderedList>
                  )
                })
              }
            </Body2OrderedListContainer>
          )}
        </Body2Container>

        <Body3Container>
          {isMobileView ? (
            <>
                <Body3ImageHeading>
                  {langStrings.termsAndCondition}
                </Body3ImageHeading>
            </>
          ) : null}
          <Body3ImageContainer>
            <Body3ImageHolder src={imagesLink.manHoldingIpad} />
          </Body3ImageContainer>

          <Body3Content>
            {isMobileView ? null : (
              <>
                <Body3ImageHeading>
                  {langStrings.termsAndCondition}
                </Body3ImageHeading>
              </>
            )}
            <Body3ListContainer>
              {
                body3ListContent.map(({ text }) => {
                  return (
                    <Body3ListItem>
                      {text}
                    </Body3ListItem>
                  )
                })
              }
            </Body3ListContainer>
          </Body3Content>
        </Body3Container>

        <Body4Container>
          <Body4TextContainer>
            <Body4Heading>{langStrings.body4Heading}</Body4Heading>
            <Body4SubTitle><p>{langStrings.protectWithSmallSteps}</p></Body4SubTitle>
            <AnchorTag href={riplayDocUrl} target="_blank">
              <Body4ButtonContainer>
                <Body4Button>{langStrings.downloadProductInformation}</Body4Button>
                <Body4Button
                  padding="12px 12px"
                >
                  <Body4ButtonImage
                    src={ImageUrl.DownloadWhite}
                  />
                </Body4Button>
              </Body4ButtonContainer>
            </AnchorTag>
          </Body4TextContainer>
          <Body4ImageContainer>
            <Body4BannerImage
              src={imagesLink.beachForeground}
            />
          </Body4ImageContainer>
        </Body4Container>
        {!isMobileView ? (
          <>
            <Body5Container>
              <Body5TextContainer>
                  <Body5Heading>{langStrings.body5Heading}</Body5Heading>
                  <Body5SubTitle>{langStrings.protectYouWithSmallSteps}</Body5SubTitle>
                  <InquiryFormDiv>
                    <InquiryForm product={"Asuransi Auto"}/>
                  </InquiryFormDiv>
              </Body5TextContainer>
            </Body5Container>
          </>
        ): null}
      </BodyContainer>
      <ScrollToTop />
      <Footer />
    </div>
  )
}

export default RoadAssistance