import React, { useState } from "react";
import { PRODUCT_LIST } from "../../constants/data";
import {
  ProductListWrapper,
  ProductListIconWrapper,
  ProductListIcon,
  ProductListTitle,
  ProductCtn,
  ProductTopCtn,
  ProductDescriptionWrapper,
  ProductDescriptionHeader,
  ProductDescriptionContent,
  ProductDescriptionBtn,
  ProductDescriptionContainer,
  ProductDescriptionButton,
  ProductDescriptionCtn,
  HeaderImage,
  FormBtmMob,
} from "./elements";
import UserCriteria from "../../containers/userCriteria/travelForm";
import { FormattedMessage } from "react-intl";
import { ImageUrl } from "../../Images";
import InquiryForm from "../../containers/userCriteria/inquiryForm";
import { useNavigate } from "react-router-dom";
import { getWindowDimensions } from "../../constants/travel";
import Modal from "../Modal";

const forms = (product) => {
  if (product.key === 'travel') {
    return <UserCriteria />
  } else {
    return <InquiryForm product={product.productName}/>
  }
};

const ProductList = () => {
  const [activeProduct, setActiveProduct] = useState("Travel");
  const [currForm, setCurrForm] = useState("travel");
  const [momdalForm, setModalForm] = useState(false)
  const [currProduct, setCurrProduct] = useState(PRODUCT_LIST[1]);
  const isMobile = getWindowDimensions().width < 1024
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    navigate({
      pathname: url,
    });
  }

  return (
    <ProductCtn>
      <ProductTopCtn bgImg={isMobile ? currProduct.backgroundImgMob : currProduct.backgroundImg}>
        <ProductDescriptionCtn>
          <ProductDescriptionWrapper>
            {(currProduct.productIcon || currProduct.productIconMob) && <HeaderImage src={isMobile ? currProduct.productIconMob : currProduct.productIcon}/>}
            <ProductDescriptionHeader textColor={currProduct.textColor}>
              <FormattedMessage
                id={currProduct.header}
                defaultMessage={currProduct.header}
                values={{
                  br: <br />,
                }}
              />
            </ProductDescriptionHeader>
            <ProductDescriptionContent textColor={currProduct.textColor}>
              <FormattedMessage
                id={currProduct.description}
                defaultMessage={currProduct.description}
                values={{
                  br: <br />,
                }}
              />
            </ProductDescriptionContent>
            <ProductDescriptionContainer onClick={() => currProduct.url && handleNavigate(currProduct.url)}>
              <ProductDescriptionButton>
                <FormattedMessage
                  id="Selengkapnya"
                  defaultMessage="Selengkapnya"
                />
              </ProductDescriptionButton>
              <ProductDescriptionBtn padding="0px">
                <img src={ImageUrl.RightArrow} />
              </ProductDescriptionBtn>
            </ProductDescriptionContainer>
          </ProductDescriptionWrapper>
        </ProductDescriptionCtn>
      </ProductTopCtn>
      <ProductListWrapper>
        {PRODUCT_LIST.map((product) => {
          return (
            <ProductListIconWrapper
              onClick={() => {
                setActiveProduct(product.label);
                setCurrForm(product.formType);
                setCurrProduct(product);
              }}
            >
              <ProductListIcon
                src={
                  activeProduct === product.label
                    ? product.activeIcon
                    : product.inactiveIcon
                }
                alt={product.key}
              />
              <ProductListTitle isActive={activeProduct === product.label}>
                {product.label}
              </ProductListTitle>
            </ProductListIconWrapper>
          );
        })}
      </ProductListWrapper>
      {currProduct && !isMobile && forms(currProduct)}
      {isMobile && <FormBtmMob onClick={() => setModalForm(true)} bgColor={currProduct.formBtnColor}>{currProduct.formBtnText}</FormBtmMob>}

      <Modal
        title={currProduct.key === 'travel' ? "Beli Asuransi Auto" : 'Cari Tau Lebih Lanjut'}
        show={momdalForm}
        onClose={() => setModalForm(false)}
      >
        {forms(currProduct)}
      </Modal>
    </ProductCtn>
  );
};

export default ProductList;
