import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CardDeatilCtn,
  CardDeatilTitle,
  CardTitle,
  HeaderText,
  ReviewOrderCtn,
  ReviewOrderdDetailsCtn,
  ReviewOrderWrapper,
  SecondHeaderText,
  TravelHolderDetails,
  PoliciesInfoCtn,
  FooterTnCCtn,
  FooterCheckCtn,
  FooterText,
  TnCAcceptBtn,
  LeftFooterMobile,
  LeftFooterMobileValueWrapper,
  LeftFooterMobileTitle,
  LeftFooterMobileValue,
  CollapsibleIconBlueFooter,
  FooterPayDetailsMobile,
  CollapsibleIconBlue,
  PaymentDetailsWrap,
} from "./elements";
import TravelHeader from "../../components/TravelHeader";
import TravelFooter from "../../components/TravelFooter";
import Timeline from "../../components/TravelTimeline";
import TravelDetails from "./components/travelDetails";
import {
  Benefitdata,
  BenefitPackageData,
} from "../../lib/travel/benefitDetailsCardData";
import PolicyHolderInfoCard from "../../components/PolicyHolderInfoCard";
import holderCardProcessor from "../../lib/travel/holderCardProcessor";
import insuredCardProcessor from "../../lib/travel/insuredCardProcessor";
import PaymentDetails from "./components/paymentDetails";
import Modal from "../../components/Modal";
import OtpActionSection from "../../components/OtpAction";
import { useAcceptTnc, useEvaluateProduct, useGetDraftData, useSaticData, useUpdateDraft, useValidateDraft } from "../../lib/api";
import TermsContent from "../../components/AppTerms/TermsContent";
import Checkbox from "../../components/Checkbox";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import cogoToast from "cogo-toast";
import { ImageUrl } from "../../Images";
import convertToIDR from "../../lib/utility/convertToIDR";
import PaymentBreakup from "./components/paymentBreakup";
import Collapsible from "../../components/Collapsible";
import { getWindowDimensions } from "../../constants/travel";

const ReviewOrder = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [otpmodal, setOtpModal] = useState(false);
  const [openTncModal, setOpenTncModal] = useState(false);
  const [tncChecked, setTncChecked] = useState(false);
  const [discountCoupData, setDisCountCoupData] = useState({});
  const [totalPremium, setTotalPremium] = useState(0)
  const [open, setOpen] = useState(false)
  const [cotravellersOpen, setCoTravellersOpen] = useState([false])
  const [showPayDetails, setShowPayDetails] = useState(false)
  const { data: tnCData, getStaticData = () => {} } = useSaticData({
    product_code: "aus-travel-insurance",
    data_type: "TNC",
  });
  const { data: draftData = {}, dataLoading: draftDataLoading, getTravelDraftData } = useGetDraftData();
  const { data: packageListData = {}, dataLoading: pkgListDataLoading, getPackageList = () => {} } =
  useEvaluateProduct();
  const draftPackageReq = draftData?.payload?.package_request || {}
  const isMobile = getWindowDimensions().width < 1024
  console.log("🚀 ~ ReviewOrder ~ isMobile:", isMobile)

  const draftInitiated =
  draftData?.status === 'QUOTE_INITIATED' ||
  draftData?.status === 'PAYMENT_ATTEMPTED';

  useEffect(() => {
    setCoTravellersOpen(draftPackageReq?.no_of_insured_persons ? new Array(draftPackageReq?.no_of_insured_persons).fill(false) : [false])
  }, [draftPackageReq])

  useEffect(() => {
    getStaticData();
    searchParams.get("draft_id") &&
      getTravelDraftData(searchParams.get("draft_id"));
  }, []);

  useEffect(() => {
    if (draftData?.payload?.package_request){
      getPackageList("aus-travel-insurance", {...draftData?.payload?.package_request, "request_type": "web",})
    }
  }, [draftData.payload])

  const isFamilyPackage = () => {
    return draftData?.payload?.package_request?.product_type === "family-A" ||
      draftData?.payload?.package_request?.product_type === "family-B"
      ? true
      : false;
  };

  const getFooter = () => {
    return (
      <FooterTnCCtn>
        <FooterCheckCtn>
          <Checkbox
            name="tnc-chekcbox"
            id="tnc-chekcbox"
            onClick={() => setTncChecked(!tncChecked)}
            checked={tncChecked}
            type="checkbox"
          />
          <FooterText>
            Saya telah membaca dan menyetujui syarat & ketentuan
          </FooterText>
        </FooterCheckCtn>
        <TnCAcceptBtn
          id="set-tnc-btn"
          loading={acceptTnCCallLoading}
          onClick={handleAcceptTnc}
          type="secondary"
          disabled={!tncChecked}
        >
          Bayar
        </TnCAcceptBtn>
      </FooterTnCCtn>
    );
  };

  const { updateDraft, isLoading: updateDraftLoading } = useUpdateDraft({
    draftId: searchParams.get("draft_id"),
    step: 2,
    onSuccess: () => {
      validateDraft()
      if (searchParams.get("draft_id")) {
        getTravelDraftData(searchParams.get("draft_id"))
      }
    },
    onFailure: (e) => console.error(e),
  });

  const {
    validateDraft,
    isLoading: validateDraftLoading
  } = useValidateDraft({
    productCode: 'aus-travel-insurance',
    draftId: searchParams.get("draft_id"),
    onSuccess: () => {},
    onFailure: (e) => {
      cogoToast.error(
        e?.response?.data?.error_details?.errors_v2.message.id ||
          e?.response?.data["validation_error"][0] ||
          "Unable to Proceed, Harap validasi formulir",
        { position: "top-right" }
      );
    }
  })

  const handleSubmit = useCallback(() => {
    navigate({
      pathname: "/payment/payment-mode",
      search: createSearchParams({
        draft_id: searchParams.get("draft_id"),
        product: "travel",
      }).toString(),
    });
  }, [searchParams.get("draft_id")]);

  const { acceptTnc, isLoading: acceptTnCCallLoading } = useAcceptTnc({
    onSuccess: () => {
      handleSubmit()
    },
    onFailure: (error) => {
      cogoToast.error(error?.response?.data?.error || 'Unable to accept TnC',{ position: "top-right" });
    }
  })

  const handleAcceptTnc = useCallback(() => {
    acceptTnc(draftData?.product_code, searchParams.get("draft_id"), tnCData?.ref_id)
  }, [acceptTnc, draftData?.product_code, searchParams.get("draft_id"), tnCData?.ref_id])

  const handleCheckSubmit = () => {
    (!draftInitiated && draftData.payload.phone_no_authenticated !== 'true') ?  setOtpModal(true) : setOpenTncModal(true)
    if (draftInitiated) {
      navigate({
        pathname: "/payment/payment-mode",
        search: createSearchParams({
          draft_id: searchParams.get("draft_id"),
          product: "travel",
        }).toString(),
      });
    }
    setTncChecked(false)
  }

  const handleDraftUpdate = () => {
    const draftPayload = {
      product_code: draftData?.product_code,
      package_code: draftData?.package_code,
      data: {
        ...draftData.payload,
        phone_no_authenticated: 'true',
      },
    };

    updateDraft(draftPayload);
  }

  const getLeftContent = () => {
    return <LeftFooterMobile>
      <LeftFooterMobileValueWrapper>
        <LeftFooterMobileTitle>
          Total Biaya
        </LeftFooterMobileTitle>
        <LeftFooterMobileValue>
          {totalPremium}
        </LeftFooterMobileValue>
      </LeftFooterMobileValueWrapper>
      <CollapsibleIconBlueFooter className={!showPayDetails ? 'open' : 'close'} onClick={() => setShowPayDetails(!showPayDetails)}/>
    </LeftFooterMobile>
  }

  const { additional_fee: {admin_fee = 0, stamp_fee = 0} = {}, coupon_data: { total_discount: discount_amount = 0, discounted_premium: discountedPremium = 0 } = {}, package_response: { premium: packagePremium = 0 } = {}, coupon_data = {}, package_response: packageDetails = {} } = draftData?.payload || {}
  const couponApplied = false
  const showDiscount = false
  const oneUserCouponApplied = false
  const paymentMap = useMemo(() => {
    setTotalPremium(couponApplied ? discountCoupData?.discounted_premium ? convertToIDR(packageDetails.total_premium + (admin_fee + stamp_fee - discountCoupData.total_discount)) : 0 : showDiscount ?  convertToIDR(packageDetails.total_premium + admin_fee + stamp_fee) : discountedPremium  ? convertToIDR(packageDetails.total_premium + (admin_fee + stamp_fee - discount_amount)) : packageDetails.total_premium ? convertToIDR(packageDetails.total_premium + admin_fee + stamp_fee) : 0)
    return {
      basic_premium: packageDetails.total_premium ? convertToIDR(packageDetails.total_premium) : '',
      discount: couponApplied ? discountCoupData?.total_discount ? convertToIDR(discountCoupData?.total_discount) : '' : showDiscount ? '' : discount_amount ? convertToIDR(discount_amount) : '',
      sub_total: couponApplied ? discountCoupData.total_discount ? convertToIDR(packageDetails.total_premium - discountCoupData.total_discount) : '' : showDiscount ? convertToIDR(packageDetails.total_premium) : discountedPremium ? convertToIDR(packageDetails.total_premium - discount_amount) : packageDetails.total_premium ? convertToIDR(packageDetails.total_premium) : '',
      total_premium: couponApplied ? discountCoupData?.discounted_premium ? convertToIDR(packageDetails.total_premium + (admin_fee + stamp_fee - discountCoupData.total_discount)) : '' : showDiscount ?  convertToIDR(packageDetails.total_premium + admin_fee + stamp_fee) : discountedPremium  ? convertToIDR(packageDetails.total_premium + (admin_fee + stamp_fee - discount_amount)) : packageDetails.total_premium ? convertToIDR(packageDetails.total_premium + admin_fee + stamp_fee) : '',
      admin_fee: convertToIDR(admin_fee),
      total_stamp_fee: convertToIDR(stamp_fee),
      total_bill: packageDetails.total_premium ? convertToIDR(packageDetails.total_premium + admin_fee + stamp_fee) : '',
      stamp_fee: stamp_fee,
      premium_per_person: packagePremium
    }
}, [draftData, packageDetails.premium, couponApplied, discountCoupData, admin_fee, stamp_fee, discount_amount, discountedPremium])

  return (
    <ReviewOrderWrapper>
      <TravelHeader />
      <Timeline header="Konfirmasi" activeStep={4} />
      <HeaderText>Konfirmasi Data & Dokumen Anda</HeaderText>
      <SecondHeaderText>Cek kembali semua form Anda dan pastikan semua informasi sudah benar </SecondHeaderText>
      <ReviewOrderCtn>
        {(draftDataLoading || pkgListDataLoading) ? <div style={{height: '60vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <BounceLoader color="#b33cfa" size={90} />
            </div> : 
          draftData?.payload && <ReviewOrderdDetailsCtn>
            <div>
              <TravelDetails
                benefitData={Benefitdata(draftData?.payload)}
                benefitPackageData={BenefitPackageData(packageListData, draftData?.payload)}
              />
              <TravelHolderDetails>
                <CardTitle onClick={() => isMobile && setOpen(!open)}>
                  <div>Wisatawan 1 (Pemegang Polis)</div>
                  {isMobile && <CollapsibleIconBlue className={!open ? 'open' : 'close'}/>}
                </CardTitle>
                  {
                    !open && Object.keys(holderCardProcessor(draftData?.payload))?.map(
                      (draftPayload) => {
                        return (
                          <CardDeatilCtn>
                            <CardDeatilTitle>{draftPayload}</CardDeatilTitle>
                            <PolicyHolderInfoCard
                              data={
                                holderCardProcessor(draftData?.payload)[
                                  draftPayload
                                ]
                              }
                            />
                          </CardDeatilCtn>
                        );
                      }
                    )
                  }
              </TravelHolderDetails>
              {draftData?.payload &&
                draftData?.payload.insured_data &&
                draftData?.payload.insured_data.length > 0 &&
                draftData?.payload.insured_data.map((insured, idx) => {
                  return (
                    <TravelHolderDetails>
                      <CardTitle onClick={() => {
                        isMobile && setCoTravellersOpen(prevArray => {
                          return [
                            ...prevArray.slice(0, idx + 1),
                            !prevArray[idx + 1], 
                            ...prevArray.slice(idx + 2)
                          ];
                        });
                      }}>
                        <div>Wisatawan {idx + 2}</div>
                        {isMobile && <CollapsibleIconBlue className={!cotravellersOpen[idx + 1] ? 'open' : 'close'}/>}
                      </CardTitle>
                      {!cotravellersOpen[idx + 1] && Object.keys(
                        isFamilyPackage()
                          ? insuredCardProcessor(insured)
                          : holderCardProcessor(insured)
                      )?.map((draftPayload) => {
                        return (
                          <CardDeatilCtn>
                            <CardDeatilTitle>{draftPayload}</CardDeatilTitle>
                            <PolicyHolderInfoCard
                              data={
                                isFamilyPackage()
                                  ? insuredCardProcessor(insured)[draftPayload]
                                  : holderCardProcessor(insured)[draftPayload]
                              }
                            />
                          </CardDeatilCtn>
                        );
                      })}
                    </TravelHolderDetails>
                  );
                })}
            </div>
            <PaymentDetails draftData={draftData} setDisCountCoupData={setDisCountCoupData} updateDraft={updateDraft} setTotalPremium={setTotalPremium}/>
          </ReviewOrderdDetailsCtn>
        }
      </ReviewOrderCtn>
      <TravelFooter
        premium={totalPremium}
        btnText="Pembayaran"
        onSubmit={() => handleCheckSubmit()}
        leftText='Total Biaya'
        loading={updateDraftLoading || validateDraftLoading}
        disabled={draftDataLoading}
        showPremium={!isMobile}
        leftContent={getLeftContent}
      />
      <Modal
        title={otpmodal ? "Konfirmasi OTP" : "Informasi"}
        show={otpmodal || openTncModal}
        onClose={() => {
          setOtpModal(false);
          setOpenTncModal(false);
        }}
        maxWidth="640px"
        showFooter
        footer={openTncModal && getFooter()}
      >
        {otpmodal && (
          <OtpActionSection
            otpInfo={{
              phoneNo: draftData?.payload?.phone_no,
            }}
            onGenerateOpId={() => {}}
            onVerifyOTPSuccess={() => {
              setOtpModal(false);
              setOpenTncModal(true);
              handleDraftUpdate()
            }}
            onResetOtpForm={() => {}}
          />
        )}

        {openTncModal && (
          <PoliciesInfoCtn>
            <TermsContent termsData={tnCData} getter={(x) => x?.data} draftData={draftData}/>
          </PoliciesInfoCtn>
        )}
      </Modal>
      <Modal
        title={'Rincian Transaksi'}
        show={showPayDetails}
        onClose={() => setShowPayDetails(false)}
      >
        <FooterPayDetailsMobile>
          <PaymentBreakup
            paymentMap={paymentMap}
            noOfInsuredPersons={draftPackageReq?.no_of_insured_persons}
            isGroupPackage={draftPackageReq?.product_type === 'group'}
            couponDetail={Object.keys(coupon_data).length ? coupon_data : Object.keys(discountCoupData) ? discountCoupData : {}}
            oneUserCouponApplied={oneUserCouponApplied}
          />
        </FooterPayDetailsMobile>
          <TravelFooter
            premium={totalPremium}
            btnText="Pembayaran"
            onSubmit={() => handleCheckSubmit()}
            leftText='Total Biaya'
            loading={updateDraftLoading || validateDraftLoading}
            disabled={draftDataLoading}
            showPremium={!isMobile}
            leftContent={getLeftContent}
          />
      </Modal>
    </ReviewOrderWrapper>
  );
};

export default ReviewOrder;
