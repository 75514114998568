import styled from 'styled-components'

export const ProgressContainer = styled.div`
  width: 40%;
  height: 8px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  margin: 10px auto;
`;

export const ProgressBar = styled.div`
  width: ${(props) => props.thumbWidth}%;
  height: 100%;
  border-radius: 4px;
  position: relative;
  background-color: #00d278;
  left: ${(props) => props.fill}%;
  transition: width 0.5s ease;
`;
